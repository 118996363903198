export function isAnalyticsDisabled(trackingRate) {
  // If trackingRate is 0 then we want Analytics to be turned off (true).
  if (trackingRate === 0) return true;

  // If trackingRate undefined/null then we want Analytics to be turned on (false).
  if (!trackingRate) return false;

  // If tracking rate is less than a randomly generated number (between 0 and 1),
  // then Analytics are disabled and we return true.
  return trackingRate < Math.random();
}

export function checkIfAnalyticsIsDisabled(
  trackingRateFromCampaign,
  disableAnalyticsFromProps
) {
  switch (true) {
    case trackingRateFromCampaign >= 0:
      return isAnalyticsDisabled(trackingRateFromCampaign);
    case disableAnalyticsFromProps:
      return true;
    default:
      return false;
  }
}
